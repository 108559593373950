// src/utils/pixel-utils.js

export const convertPixelsToImageData = (pixels, scale = 10) => {
  const originalSize = 24;
  const scaledSize = originalSize * scale;
  const canvas = document.createElement("canvas");
  canvas.width = scaledSize;
  canvas.height = scaledSize;
  const ctx = canvas.getContext("2d");

  // Fill the canvas with white first
  ctx.fillStyle = "#FFFFFF";
  ctx.fillRect(0, 0, scaledSize, scaledSize);

  pixels.forEach((color, i) => {
    if (color !== null) {
      const x = (i % originalSize) * scale;
      const y = Math.floor(i / originalSize) * scale;
      ctx.fillStyle = color;
      ctx.fillRect(x, y, scale, scale);
    }
  });

  return canvas.toDataURL();
};

export const makeTestArt = () => {
  const startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000); // 7 days ago
  const colors = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FF00FF",
    "#00FFFF",
    "#FFA500",
    "#800080",
    "#008000",
    "#000000",
  ];

  const testTiles = Array(100)
    .fill()
    .map((_, index) => ({
      imageData: convertPixelsToImageData(
        Array(576)
          .fill(null)
          .map(() => colors[Math.floor(Math.random() * colors.length)])
      ),
      contributor: `Test User ${index + 1}`,
      submissionTime: new Date(
        startDate.getTime() + Math.random() * (Date.now() - startDate.getTime())
      ).toISOString(),
    }));

  const testArtPiece = {
    name: "Pixel Harmony #1",
    tiles: testTiles,
    imageData: convertPixelsToImageData(
      Array(576)
        .fill(null)
        .map(() => colors[Math.floor(Math.random() * colors.length)])
    ), // This represents the combined image
    startDate: startDate.toISOString(),
    completionDate: new Date().toISOString(),
  };

  return testArtPiece;
};
