// src/App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import Navigation from "./components/Navigation";
import PixelCanvas from "./components/PixelCanvas";
import SubmissionForm from "./components/SubmissionForm";
import LargeArtPiece from "./components/LargeArtPiece";
import Gallery from "./components/Gallery";
import { convertPixelsToImageData } from "./utils/pixel-utils";
import {
  db,
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  query,
  orderBy,
  limit,
  where,
} from "./firebase";

const AppContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const App = () => {
  const [currentArtPiece, setCurrentArtPiece] = useState({ tiles: [] });
  const [completedArtPieces, setCompletedArtPieces] = useState([]);

  useEffect(() => {
    fetchArtPieces();
  }, []);

  const fetchArtPieces = async () => {
    try {
      const artPiecesCollection = collection(db, "artPieces");
      const completedArtQuery = query(
        artPiecesCollection,
        where("isCompleted", "==", true),
        orderBy("completionDate", "desc"),
        limit(10)
      );
      const inProgressArtQuery = query(
        artPiecesCollection,
        where("isCompleted", "==", false),
        orderBy("lastUpdateDate", "desc"),
        limit(1)
      );

      const [completedSnapshot, inProgressSnapshot] = await Promise.all([
        getDocs(completedArtQuery),
        getDocs(inProgressArtQuery),
      ]);

      const fetchedCompletedArt = completedSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCompletedArtPieces(fetchedCompletedArt);

      if (!inProgressSnapshot.empty) {
        const inProgressArt = {
          id: inProgressSnapshot.docs[0].id,
          ...inProgressSnapshot.docs[0].data(),
        };
        setCurrentArtPiece(inProgressArt);
      } else {
        setCurrentArtPiece({ tiles: [] });
      }
    } catch (error) {
      alert("An error occurred while loading art pieces. Please try again.");
    }
  };

  const handleSubmission = async (pixels, displayName, navigate) => {
    if (!pixels || pixels.every((pixel) => pixel === null)) {
      alert("Please create your pixel art first!");
      return;
    }

    const imageData = convertPixelsToImageData(pixels, 20);
    const submissionTime = new Date().toISOString();
    const newTile = { imageData, contributor: displayName, submissionTime };

    try {
      // First, fetch the latest state of the current art piece
      const artPiecesCollection = collection(db, "artPieces");
      const inProgressArtQuery = query(
        artPiecesCollection,
        where("isCompleted", "==", false),
        orderBy("lastUpdateDate", "desc"),
        limit(1)
      );
      const inProgressSnapshot = await getDocs(inProgressArtQuery);

      if (inProgressSnapshot.empty) {
        // If there's no in-progress art piece, create a new one
        const newArtPiece = {
          name: `Pixel Harmony #${completedArtPieces.length + 1}`,
          tiles: [newTile],
          startDate: submissionTime,
          lastUpdateDate: submissionTime,
          isCompleted: false,
        };
        await addDoc(collection(db, "artPieces"), newArtPiece);
      } else {
        // If there's an in-progress art piece, check if it's still incomplete
        const currentArtPiece = inProgressSnapshot.docs[0].data();
        if (
          currentArtPiece.tiles.length >= 100 ||
          currentArtPiece.isCompleted
        ) {
          // The art piece is already complete, create a new one
          const newArtPiece = {
            name: `Pixel Harmony #${completedArtPieces.length + 1}`,
            tiles: [newTile],
            startDate: submissionTime,
            lastUpdateDate: submissionTime,
            isCompleted: false,
          };
          await addDoc(collection(db, "artPieces"), newArtPiece);
        } else {
          // Update the existing in-progress art piece
          const updatedTiles = [...currentArtPiece.tiles, newTile];
          const artPieceRef = doc(
            db,
            "artPieces",
            inProgressSnapshot.docs[0].id
          );
          const updateData = {
            tiles: updatedTiles,
            lastUpdateDate: submissionTime,
            isCompleted: updatedTiles.length === 100,
            completionDate: updatedTiles.length === 100 ? submissionTime : null,
          };
          await updateDoc(artPieceRef, updateData);
        }
      }

      // Fetch updated art pieces
      await fetchArtPieces();

      navigate("/art-in-progress");
    } catch (error) {
      alert(
        "An error occurred while submitting your art piece. Please try again."
      );
    }
  };

  return (
    <Router>
      <Navigation />
      <AppContainer>
        <ContentContainer>
          <Routes>
            <Route path="/" element={<Home onSubmit={handleSubmission} />} />
            <Route
              path="/art-in-progress"
              element={<ArtInProgressView currentArtPiece={currentArtPiece} />}
            />
            <Route
              path="/gallery"
              element={<Gallery artPieces={completedArtPieces} />}
            />
          </Routes>
        </ContentContainer>
      </AppContainer>
    </Router>
  );
};

const Home = ({ onSubmit }) => {
  const navigate = useNavigate();
  const [currentPixels, setCurrentPixels] = useState(Array(576).fill(null));

  const handleSubmit = (displayName) => {
    onSubmit(currentPixels, displayName, navigate);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <PixelCanvas onPixelsChange={setCurrentPixels} />
      <SubmissionForm onSubmit={handleSubmit} />
    </div>
  );
};

const ArtInProgressView = ({ currentArtPiece }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: "0 20px",
    }}
  >
    <h2>Art in the Making</h2>
    <LargeArtPiece tiles={currentArtPiece.tiles} />
    <p>{currentArtPiece.tiles.length} out of 100 submissions</p>
  </div>
);

export default App;
