// src/components/LargeArtPiece.js
import React, { useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";

const ArtPieceContainer = styled.div`
  width: 500px;
  height: 500px;
  padding: 10px;
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 400px;
    height: 400px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 2px;
  width: 100%;
  height: 100%;
  background-color: #555;
`;

const Tile = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    props.imageData ? `url(${props.imageData})` : "none"};
  background-size: cover;
  background-color: ${(props) => (props.imageData ? "transparent" : "#e0e0e0")};
  border: 1px solid #999;
  cursor: ${(props) => (props.isSubmitted ? "pointer" : "default")};
`;

const LargeArtPiece = ({ tiles }) => {
  const [selectedTile, setSelectedTile] = useState(null);

  const handleTileClick = (tile) => {
    if (tile) {
      setSelectedTile(tile);
    }
  };

  // Create an array of 100 elements, fill with existing tiles or null
  const fullTileSet = Array(100)
    .fill(null)
    .map((_, index) => tiles[index] || null);

  return (
    <ArtPieceContainer>
      <Grid>
        {fullTileSet.map((tile, index) => (
          <Tile
            key={index}
            onClick={() => handleTileClick(tile)}
            imageData={tile ? tile.imageData : null}
            isSubmitted={!!tile}
          />
        ))}
      </Grid>
      {selectedTile && (
        <Modal tile={selectedTile} onClose={() => setSelectedTile(null)} />
      )}
    </ArtPieceContainer>
  );
};

export default LargeArtPiece;
