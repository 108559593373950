// src/components/Navigation.js
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "./logo.png"; // Import the logo

const NavContainer = styled.nav`
  background-color: #2c3e50;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 40px; // Adjust this value to fit your navbar height
  margin-right: 20px;
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 350px; // Adjust this value as needed

  @media (max-width: 768px) {
    max-width: 300px; // Adjust for mobile
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #34495e;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 5px;
  }
`;

const Navigation = () => {
  return (
    <NavContainer>
      <Logo src={logo} alt="JoinedPixelArt Logo" />
      <NavLinks>
        <NavLink to="/">Draw</NavLink>
        <NavLink to="/art-in-progress">
          <span className="desktop-text">Art in the Making</span>
          <span className="mobile-text">Art in Progress</span>
        </NavLink>
        <NavLink to="/gallery">Gallery</NavLink>
      </NavLinks>
    </NavContainer>
  );
};

export default Navigation;
