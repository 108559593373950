// src/components/GalleryItemModal.js
import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  padding: 20px 30px;
  background-color: #f8f8f8;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: 1px solid #e0e0e0;
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: #333;
  font-size: 24px;
`;

const ModalBody = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const InfoSection = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
`;

const InfoTitle = styled.h3`
  margin: 0 0 10px 0;
  color: #444;
`;

const InfoText = styled.p`
  margin: 5px 0;
  color: #666;
`;

const ContributorsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
`;

const ContributorItem = styled.li`
  padding: 5px 0;
  border-bottom: 1px solid #e0e0e0;
  &:last-child {
    border-bottom: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;

  &:hover {
    color: #333;
  }
`;

const GalleryItemModal = ({ artPiece, onClose }) => {
  const calculateDuration = (startDate, endDate) => {
    const duration = new Date(endDate) - new Date(startDate);
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours} hours, ${minutes} minutes`;
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalHeader>
          <ModalTitle>{artPiece.name}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Image src={artPiece.imageData} alt={artPiece.name} />
          <InfoSection>
            <InfoTitle>Artwork Details</InfoTitle>
            <InfoText>
              Started: {new Date(artPiece.startDate).toLocaleString()}
            </InfoText>
            <InfoText>
              Completed: {new Date(artPiece.completionDate).toLocaleString()}
            </InfoText>
            <InfoText>
              Duration:{" "}
              {calculateDuration(artPiece.startDate, artPiece.completionDate)}
            </InfoText>
          </InfoSection>
          <InfoSection>
            <InfoTitle>Contributors</InfoTitle>
            <ContributorsList>
              {artPiece.tiles.map((tile, index) => (
                <ContributorItem key={index}>
                  {tile.contributor} -{" "}
                  {new Date(tile.submissionTime).toLocaleString()}
                </ContributorItem>
              ))}
            </ContributorsList>
          </InfoSection>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

export default GalleryItemModal;
