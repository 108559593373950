import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const CanvasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f4f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const CanvasWrapper = styled.div`
  touch-action: none;
  overflow: hidden;
`;

const Button = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #357abd;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ColorPickerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ColorPreview = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 2px #4a90e2;
  background-color: ${(props) => props.color};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const ColorInput = styled.input`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  width: 360px;
  height: 360px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Pixel = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color};
  border: 1px solid #f0f0f0;
`;

const PixelCanvas = ({ onPixelsChange }) => {
  const [pixels, setPixels] = useState(Array(576).fill(null));
  const [currentColor, setCurrentColor] = useState("#000000");
  const [isDrawing, setIsDrawing] = useState(false);
  const [history, setHistory] = useState([Array(576).fill(null)]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const gridRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    onPixelsChange(pixels);
  }, [pixels, onPixelsChange]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "z") {
        undo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [historyIndex]);

  const getPixelIndex = (clientX, clientY) => {
    const rect = gridRef.current.getBoundingClientRect();
    let x = Math.floor(((clientX - rect.left) / rect.width) * 24);
    let y = Math.floor(((clientY - rect.top) / rect.height) * 24);

    // Clamp x and y values to be within the canvas
    x = Math.max(0, Math.min(x, 23));
    y = Math.max(0, Math.min(y, 23));

    return y * 24 + x;
  };

  const paintPixel = (index) => {
    setPixels((prevPixels) => {
      const newPixels = [...prevPixels];
      newPixels[index] = currentColor === "#FFFFFF" ? null : currentColor;
      return newPixels;
    });
  };

  const handleMouseDown = (event) => {
    setIsDrawing(true);
    const index = getPixelIndex(event);
    paintPixel(index);
    addToHistory();
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    setPixels(Array(576).fill(null));
    addToHistory();
  };

  const addToHistory = () => {
    const newHistory = history.slice(0, historyIndex + 1);
    newHistory.push([...pixels]);
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  };

  const undo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setPixels(history[historyIndex - 1]);
    }
  };

  const preventScroll = (event) => {
    event.preventDefault();
  };

  const enableDrawingMode = () => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener("touchmove", preventScroll, {
        passive: false,
      });
      document.body.style.overflow = "hidden";
    }
  };

  const disableDrawingMode = () => {
    if (wrapperRef.current) {
      wrapperRef.current.removeEventListener("touchmove", preventScroll);
      document.body.style.overflow = "";
    }
  };

  const handleTouchStart = (event) => {
    enableDrawingMode();
    setIsDrawing(true);
    const touch = event.touches[0];
    const index = getPixelIndex(touch);
    paintPixel(index);
    addToHistory();
  };

  const handleMouseMove = (event) => {
    if (!isDrawing) return;
    const rect = gridRef.current.getBoundingClientRect();
    if (
      event.clientX < rect.left ||
      event.clientX > rect.right ||
      event.clientY < rect.top ||
      event.clientY > rect.bottom
    ) {
      return;
    }
    const index = getPixelIndex(event.clientX, event.clientY);
    paintPixel(index);
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    if (!isDrawing) return;
    const touch = event.touches[0];
    const rect = gridRef.current.getBoundingClientRect();
    if (
      touch.clientX < rect.left ||
      touch.clientX > rect.right ||
      touch.clientY < rect.top ||
      touch.clientY > rect.bottom
    ) {
      return;
    }
    const index = getPixelIndex(touch.clientX, touch.clientY);
    paintPixel(index);
  };

  const handleTouchEnd = () => {
    disableDrawingMode();
    setIsDrawing(false);
  };

  return (
    <CanvasWrapper ref={wrapperRef}>
      <CanvasContainer>
        <ControlsContainer>
          <ColorPickerContainer>
            <ColorPreview color={currentColor} />
            <ColorInput
              type="color"
              value={currentColor}
              onChange={(e) => setCurrentColor(e.target.value)}
            />
          </ColorPickerContainer>
          <ButtonGroup>
            <Button onClick={clearCanvas}>Clear</Button>
            <Button onClick={undo} disabled={historyIndex === 0}>
              Undo
            </Button>
          </ButtonGroup>
        </ControlsContainer>
        <Grid
          ref={gridRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {pixels.map((color, index) => (
            <Pixel key={index} color={color || "transparent"} />
          ))}
        </Grid>
      </CanvasContainer>
    </CanvasWrapper>
  );
};

export default PixelCanvas;
