// src/components/Modal.js
import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  max-height: 90%;
  position: relative;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 480px;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Contributor = styled.h2`
  margin: 0 0 15px 0;
  color: #333;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const SubmissionTime = styled.p`
  margin: 0;
  color: #666;
  font-style: italic;
  font-size: 14px;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;

  &:hover {
    color: #ff4444;
  }
`;

const Modal = ({ tile, onClose }) => {
  if (!tile) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ImageContainer>
          <Image src={tile.imageData} alt="Submitted artwork" />
        </ImageContainer>
        <InfoContainer>
          <Contributor>{tile.contributor}</Contributor>
          <SubmissionTime>
            Submitted on: {new Date(tile.submissionTime).toLocaleString()}
          </SubmissionTime>
        </InfoContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
