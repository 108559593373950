// src/components/Gallery.js
import React, { useState } from "react";
import styled from "styled-components";
import GalleryItemModal from "./GalleryItemModal";

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const ArtPieceContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ArtPiecePreview = styled.div`
  width: 100%;
  aspect-ratio: 1;
  background-image: ${(props) => `url(${props.imageData})`};
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

const ArtPieceInfo = styled.div`
  padding: 15px;
  background-color: #f8f8f8;
`;

const ArtPieceName = styled.h3`
  margin: 0 0 10px 0;
  font-size: 18px;
`;

const ArtPieceDetail = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #666;
`;

const EmptyGallery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  text-align: center;
`;

const Gallery = ({ artPieces }) => {
  const [selectedArtPiece, setSelectedArtPiece] = useState(null);

  const calculateDuration = (startDate, endDate) => {
    const duration = new Date(endDate) - new Date(startDate);
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours} hours, ${minutes} minutes`;
  };

  if (artPieces.length === 0) {
    return (
      <EmptyGallery>
        <h2>Gallery is Empty</h2>
        <p>Completed artworks will appear here. Start creating!</p>
      </EmptyGallery>
    );
  }

  return (
    <>
      <GalleryGrid>
        {artPieces.map((piece, index) => (
          <ArtPieceContainer key={index}>
            <ArtPiecePreview
              imageData={piece.imageData}
              onClick={() => setSelectedArtPiece(piece)}
            />
            <ArtPieceInfo>
              <ArtPieceName>{piece.name}</ArtPieceName>
              <ArtPieceDetail>
                Completed: {new Date(piece.completionDate).toLocaleDateString()}
              </ArtPieceDetail>
              <ArtPieceDetail>
                Duration:{" "}
                {calculateDuration(piece.startDate, piece.completionDate)}
              </ArtPieceDetail>
            </ArtPieceInfo>
          </ArtPieceContainer>
        ))}
      </GalleryGrid>
      {selectedArtPiece && (
        <GalleryItemModal
          artPiece={selectedArtPiece}
          onClose={() => setSelectedArtPiece(null)}
        />
      )}
    </>
  );
};

export default Gallery;
