// src/components/SubmissionForm.js
import React, { useState } from "react";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin-top: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  border: 2px solid #4a90e2;
  border-radius: 25px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #357abd;
  }
`;

const SubmitButton = styled.button`
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #357abd;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const SubmissionForm = ({ onSubmit }) => {
  const [displayName, setDisplayName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (displayName.trim() === "") {
      alert("Please enter your display name");
      return;
    }
    onSubmit(displayName);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="text"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
        placeholder="Enter your display name"
        required
      />
      <SubmitButton type="submit">Submit Artwork</SubmitButton>
    </Form>
  );
};

export default SubmissionForm;
